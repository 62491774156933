*{
  font-family:  "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体", "ヒラギノ角ゴシック", "Hiragino Sans";/*"メイリオ", "Meiryo", sans-serif*/
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}


  